import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Establishment } from '@/models/establishment';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';

export const getEstablishmentThunk = createAsyncThunk<
    Establishment,
    { establishment_name: string },
    { rejectValue: Error }
>('Establishment', async (params, thunkApi) => {
    return request({
        url: `/establishments/${params.establishment_name}`,
        method: 'GET'
    })
        .then((res: ApiResponse<Establishment>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            // if (err.status === 401) {
            // }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const establishmentSlice = createSlice({
    name: 'establishment',
    initialState: { data: {} as Establishment },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEstablishmentThunk.fulfilled, (state, { payload }) => {
            state.data = payload;
        });
    }
});
