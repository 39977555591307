import ReactModal from 'react-modal';
import { ProductFormat } from '@/models/product';
import styles from './ProductInfo.module.scss';
import { useEffect } from 'react';

interface ProductInfoProps {
    name: string;
    image: string;
    description: string;
    formats: ProductFormat[];
    selectedFormatId: string;
    isOpen: boolean;
    onRequestClose: () => void;
    onFormatSelect: (formatId: string) => void;
}

const ProductInfo = ({ name, image, description, formats, selectedFormatId, isOpen, onRequestClose, onFormatSelect }: ProductInfoProps) => {

    useEffect(() => {
        ReactModal.setAppElement('body'); // Establece el elemento raíz de la aplicación
    }, []);

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
        >
            <button onClick={onRequestClose} className={styles.modalCloseButton}>×</button>
            <img className={styles.modalImage} src={image} alt={name} />
            <h2 className={styles.modalTitle}>{name}</h2>
            <p className={styles.modalDescription}>{description}</p>
            <div className={styles.formatOptions}>
                {formats.map((format) => (
                    <div
                        key={format.id}
                        className={`${styles.formatOption} ${format.id === selectedFormatId ? styles.selected : ''}`}
                        onClick={() => onFormatSelect(format.id)}
                    >
                        <span className={styles.formatName}>{format.name}</span>
                        <span className={styles.formatPrice}>{format.price.toFixed(2)}€</span>
                    </div>
                ))}
            </div>
        </ReactModal>
    );
};

export default ProductInfo;
