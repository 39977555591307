import { combineReducers } from 'redux';
import { productListSlice } from './productList.slice';

export const productReducer = combineReducers({
    productList: productListSlice.reducer
});

export const productActions = {
    ...productListSlice.actions
};
