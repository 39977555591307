import { ProductType } from '@/models/product';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface ProductListData {
    [key: string]: ProductType;
}

export const getProductListThunk = createAsyncThunk<
    ProductListData,
    { establishment_id: string },
    { rejectValue: Error }
>('ProductListData', async (params, thunkApi) => {
    return request({
        url: `/establishments/${params.establishment_id}/products`,
        method: 'GET'
    })
        .then((res: ApiResponse<ProductListData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            // if (err.status === 401) {
            // }
            return thunkApi.rejectWithValue(err.data);
        });
});

export const productListSlice = createSlice({
    name: 'productList',
    initialState: { data: {} as ProductListData},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProductListThunk.fulfilled, (state, { payload }) => {
            state.data = payload;
        });
    }
});
