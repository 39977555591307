import styles from './Header.module.scss';

interface HeaderProps {
    image: string;
    color: string;
}

const Header = ({ image, color }: HeaderProps) => {

    return (
        <header className={styles.header} style={{backgroundColor: color}}>
            <div className={styles.container}>
                <img src={image} alt="Logo" className={styles.logo} />
            </div>
        </header>
    );
};

export default Header;