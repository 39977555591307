import { ProductFormat } from "@/models/product";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from '@mui/material/Select';


interface SelectProps {
    placeholder?: string;
    options: ProductFormat[];
    selectedOption: string | undefined;
    open: boolean;
    setSelectedOption: (selectedOption: string) => void;
    onClose: () => void;
}


const ProductFormatSelect = ({ placeholder, options, selectedOption, open, setSelectedOption, onClose }: SelectProps) => {

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            {/* <InputLabel id="demo-simple-select-label">formato</InputLabel> */}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedOption}
                // label='formato'
                onChange={(event) => setSelectedOption(event.target.value)}
                placeholder={placeholder ?? ''}
                open={open}
                onClose={onClose}
            >
                {options.map((option: ProductFormat) => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ProductFormatSelect;