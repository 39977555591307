import { Tab, Tabs, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004b91',
        },
    },
});


interface TabsMenuProps {
    index: number;
    setIndex: (value: number) => void;
    menuTypes: string[];
}


const TabsMenu = ({ index, setIndex, menuTypes }: TabsMenuProps) => {
    return (
        <ThemeProvider theme={theme}>
            <Tabs
                value={index}
                onChange={(event, value) => setIndex(value)}
                variant="scrollable"
                scrollButtons={true}
                allowScrollButtonsMobile
                indicatorColor="primary"
            >
                {menuTypes && menuTypes.map((optionsType: string) => (
                    <Tab
                        key={optionsType}
                        label={optionsType}
                    />
                ))}
            </Tabs>
        </ThemeProvider>
    );
};

export default TabsMenu;
