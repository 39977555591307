import { combineReducers } from 'redux';
import { establishmentSlice } from './establishment.slice';

export const establishmentReducer = combineReducers({
    establishment: establishmentSlice.reducer
});

export const establishmentActions = {
    ...establishmentSlice.actions
};
