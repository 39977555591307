import { useNavigate } from 'react-router-dom';
import styles from './NavigationButtons.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

interface NavigationButtonsProps {
    backgroundColor: string;
    textColor: string;
}


const NavigationButtons = ({ backgroundColor, textColor }: NavigationButtonsProps) => {

    const navigate = useNavigate();

    //TODO gestionar posibles acciones según suscripción

    return (
        <div className={styles.navigationButtons}>
            <div
                className={styles.home}
                onClick={() => navigate('/')}
                style={{ backgroundColor: backgroundColor, color: textColor }}
            >
                <FontAwesomeIcon icon={faHome} size='2xl' />
            </div>
        </div>
    );
};

export default NavigationButtons;
