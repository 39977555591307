import React from 'react';
import styles from './Footer.module.scss';

interface FooterProps {
    footerType: string;
    footerColor: string;
    children?: React.ReactNode;
}

const Footer = ({ footerType, footerColor, children }: FooterProps) => {

    const getFooterType = () => {
        switch (footerType) {
            case "1":
                return styles.roundedSection;
            case "2":
                return styles.simpleFooter;
            case "3":
                return '';
            default:
                return styles.roundedSection;
        }
    };

    return (
        <div className={`${styles.footer} ${getFooterType()}`} style={{ backgroundColor: footerColor }}>
            {children}
        </div>
    );
};

export default Footer;
