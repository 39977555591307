import { Product, ProductFormat } from '@/models/product';
import { useEffect, useState } from 'react';
import lopedimosIcon from '@/assets/images/lopedimos-icon.png';

interface OptionProps {
    option: Product;
}

const OptionController = ({ option }: OptionProps) => {
    const { name, description, formats } = option;

    const [image, setImage] = useState<string>(lopedimosIcon);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [formatName, setFormatName] = useState<string | undefined>(undefined);
    const [selectedFormat, setSelectedFormat] = useState<ProductFormat | undefined>(undefined);
    const [selectedFormatId, setSelectedFormatId] = useState<string>(formats[0].id);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (selectedFormat) {
            setPrice(selectedFormat.price);
            setImage(selectedFormat.image ?? lopedimosIcon);
            setFormatName(selectedFormat.name);
        }
    }, [selectedFormat]);

    useEffect(() => {
        if (selectedFormatId) {
            const format = formats?.find((format) => format.id === selectedFormatId);
            setSelectedFormat(format);
        }
    }, [selectedFormatId]);

    return {
        name,
        description,
        image,
        price,
        formats,
        formatName,
        selectedFormatId,
        dropdownOpen,
        isModalOpen,
        setSelectedFormatId,
        setDropdownOpen,
        setIsModalOpen
    };
};

export default OptionController;
