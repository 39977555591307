import { useState } from 'react';
import styles from './ExpandableText.module.scss';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandableTextProps {
    text: string;
    maxLength: number;
}

const ExpandableText = ({ text, maxLength }: ExpandableTextProps) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggleExpand = () => {
        // setExpanded(!expanded);
    };

    const displayText = (text ?? '').length < maxLength ? text : expanded ? text : (text.slice(0, maxLength) + '...');

    return (
        <div>
            <p className={`${styles['expandable-text']} ${expanded && styles['expanded']}`}>{displayText}</p>
            {(text ?? '').length > maxLength && (
                <button onClick={handleToggleExpand} className={styles['expand-button']}>
                    <FontAwesomeIcon icon={faAngleDown} className={`${styles['expand-icon']} ${expanded ? styles['expanded'] : ''}`} />
                </button>
            )}
        </div>
    );
};

export default ExpandableText;
