import { getEstablishmentThunk } from './establishment/establishment.slice';
import { getProductListThunk } from './product/productList.slice';

const rootThunkData: any = [getProductListThunk, getEstablishmentThunk];

const rootThunkHandler = (data: any[]) => {
    const newJ = {
        data: {}
    };

    data.map((e) => {
        newJ.data = {
            ...newJ.data,
            [e.pending as string]: (state: { isLoading: boolean }) => {
                state.isLoading = true;
            },
            [e.fulfilled as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            },
            [e.rejected as string]: (state: { isLoading: boolean }) => {
                state.isLoading = false;
            }
        };
    });

    return newJ.data;
};

export const rootThunk = rootThunkHandler(rootThunkData);
