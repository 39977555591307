import { combineReducers } from 'redux';
import { loaderReducer } from './loader';
import { productReducer } from './product';
import { establishmentReducer } from './establishment';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    loader: loaderReducer,
    product: productReducer,
    establishment: establishmentReducer,
});

export default rootReducer;
