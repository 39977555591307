// Home.tsx
import styles from './Home.module.scss';
import HomeController from './Home.controller';
import banner from '@/assets/images/banner.jpg';
import { useNavigate } from 'react-router-dom';
import Banner from '@/components/Banner/Banner';
import Footer from '@/components/Footer/Footer';

const Home = () => {
    const navigate = useNavigate();

    const { spot_id, establishmentName, establishmentCustom } = HomeController();

    return (
        <div className={styles.homeContent}>
            {establishmentName && establishmentCustom &&
                <>
                    <Banner
                        establishmentName={establishmentName}
                        bannerImage={establishmentCustom.cover_image ?? banner}
                        bannerType={establishmentCustom.cover_type ?? '2'}
                        bannerTextColor={establishmentCustom.cover_text_color ?? ''}
                    />

                    <div className={styles.centerButtonContainer}>
                        <button
                            className={styles.centerButton}
                            onClick={() => navigate(`/${spot_id ? spot_id + '/' : ''}menu`)}
                            style={{ 
                                backgroundColor: establishmentCustom.cover_button_color ?? '',
                                color: establishmentCustom.cover_button_text_color ?? ''
                            }}
                        >VER CARTA</button>
                    </div>

                    {establishmentCustom.home_footer_type !== '3' &&
                        <Footer
                            footerType={establishmentCustom.home_footer_type ?? '1'}
                            footerColor={establishmentCustom.home_footer_color ?? ''}
                        >
                            {/* <UserActions /> */}
                        </Footer>
                    }
                </>
            }
        </div>
    );
};

export default Home;
