import styles from './OptionsList.module.scss';
import { Product } from '@/models/product';
import Option from '@/components/Option/Option';

interface OptionsListProps {
    optionsList: Product[];
}

const OptionsList = ({ optionsList }: OptionsListProps) => {

    return (
        <div className={styles.optionsList}>
            {optionsList?.map((option: Product) => (
                <Option
                    key={option.id}
                    option={option}
                />
            ))}
        </div>
    );
};

export default OptionsList;
