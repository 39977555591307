import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';


const App = () => {

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/:spot_id?/menu" element={<Menu />} />
                    <Route path="/:spot_id?" element={<Home />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export default App;
