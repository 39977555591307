import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { useParams } from 'react-router-dom';

const HomeController = () => {
    const { spot_id } = useParams();

    const { name: establishmentName, custom_options: establishmentCustom } = useSelector(
        (state: RootState) => state.establishment.establishment.data
    );

    //TODO para cuando haya info de las mesas
    // useEffect(() => {
    //     if (spot_id) {   
    //         //TODO si hay spot_id, se pueden utilizar funcionalidades de pago (si 
    //          están contratadas). Si no, únicamente se muestra la carta
    //     }
    // }, [spot_id]);

    useEffect(() => {
        //TODO
    }, []);

    return { spot_id, establishmentName, establishmentCustom };
};

export default HomeController;
