import styles from './Option.module.scss';
import { Product } from '@/models/product';
import OptionController from './Option.controller';
import ProductFormatSelect from '@/components/ProductFormatSelect/ProductFormatSelect';
import ExpandableText from '@/components/ExpandableText/ExpandableTex';
import ProductInfo from '@/components/ProductInfo/ProductInfo';

interface OptionProps {
    option: Product;
}

const Option = (props: OptionProps) => {

    const {
        name,
        description,
        image,
        price,
        formats,
        selectedFormatId,
        dropdownOpen,
        isModalOpen,
        setSelectedFormatId,
        setDropdownOpen,
        setIsModalOpen
    } = OptionController(props);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className={styles.option} onClick={handleOpenModal}>
                <img className={styles.optionImage} src={image} alt={name} />
                <div className={styles.optionDetails}>
                    <div className={styles.optionName}>{name} {
                        formats && formats.length > 1 && (
                            <button className={styles.addBtn} onClick={handleOpenModal}>+</button>
                        )}</div>
                    <ExpandableText text={description} maxLength={50} />
                    <div className={styles.optionPriceAndQuantity}>
                        {price && <div className={styles.optionPrice}>{price?.toFixed(2)}€</div>}
                        {/* <div className={styles.quantitySelector}>
                        <button>-</button>
                        <input type="number" value={0} />
                        <button>+</button>
                    </div> */}
                    </div>
                </div>
                {dropdownOpen && (
                    <div className={styles.productFormatSelect}>
                        <ProductFormatSelect
                            placeholder='formato'
                            options={formats}
                            selectedOption={selectedFormatId}
                            open={dropdownOpen}
                            setSelectedOption={setSelectedFormatId}
                            onClose={() => setDropdownOpen(false)}
                        />
                    </div>
                )}
            </div>

            <ProductInfo
                name={name}
                image={image}
                description={description}
                formats={formats}
                selectedFormatId={selectedFormatId}
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                onFormatSelect={(formatId: string) => setSelectedFormatId(formatId)}
            />
        </>
    );
};

export default Option;
