import Loader from '@/components/Loader/Loader';
import { RootState } from '@/store/rootReducer';
import React, { useEffect } from 'react';
import { useAppDispatch } from '@/index';
import { useSelector } from 'react-redux';
import styles from './Layout.module.scss';
import Header from '@/components/Header/Header';
import { getEstablishmentThunk } from '@/store/establishment/establishment.slice';
import logo from '@/assets/icons/lopedimos.png';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {

    const dispatch = useAppDispatch();

    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);
    const establishment = useSelector(
        (state: RootState) => state.establishment.establishment.data
    );

    useEffect(() => {
        if (!establishment || Object.keys(establishment).length === 0) {
            let subdomain = '';
            if (process.env.NODE_ENV === 'development' && false) {
                subdomain = 'db_lopedimos';
            } else {
                const { hostname } = window.location;
                subdomain = hostname.split('.')[0];
            }

            dispatch(getEstablishmentThunk({ establishment_name: subdomain }));
        }
    }, [establishment]);

    return (
        <>
            <Loader isLoading={isLoading} />
            <Header 
                image={establishment?.custom_options?.header_image ?? logo}
                color={establishment?.custom_options?.header_color ?? ''}
            />
            <div className={styles.container}>
                <div className={styles.content}>{children}</div>
            </div>
        </>
    );
};

export default Layout;
