import { useEffect, useRef, useState } from 'react';
import banner from '@/assets/images/banner.jpg';
import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { FilterOption } from '@/models/product';
import { getProductListThunk } from '@/store/product/productList.slice';
import { useAppDispatch } from '@/index';

const MenuController = () => {
    // const { spot_id } = useParams();

    const swiperRef = useRef();

    const dispatch = useAppDispatch();

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [allergens, setAllergens] = useState<FilterOption[] | undefined>(undefined);
    const [philosophies, setPhilosophies] = useState<FilterOption[] | undefined>(undefined);
    const [tabIndex, setTabIndex] = useState(0);
    const [menuTypes, setMenuTypes] = useState<string[]>([]);

    const { id: establishmentId, custom_options: establishmentCustom } = useSelector(
        (state: RootState) => state.establishment.establishment.data
    );
    const { data } = useSelector((state: RootState) => state.product.productList);

    useEffect(() => {
        if (data) {
            setMenuTypes(Object.keys(data));
        }
    }, [data]);

    useEffect(() => {
        if (establishmentId) {
            dispatch(getProductListThunk({ establishment_id: establishmentId }));
        }
    }, [establishmentId]);

    useEffect(() => {
        console.log('tabIndex', tabIndex);
        swiperRef.current?.slideTo(tabIndex);
    }, [tabIndex]);

    useEffect(() => {
        // dispatch(spotActions.setSpot(spot_id));
    }, []);

    return {
        establishmentCustom,
        searchTerm,
        allergens,
        philosophies,
        tabIndex,
        menuTypes,
        data,
        swiperRef,
        setSearchTerm,
        setAllergens,
        setPhilosophies,
        setTabIndex
    };
};

export default MenuController;
