import styles from './Menu.module.scss';
import MenuController from './Menu.controller';
// import MenuFilter from '@/components/MenuFilter/MenuFilter';
import TabsMenu from '@/components/TabsMenu/TabsMenu';
import OptionsList from '@/components/OptionsList/OptionsList';
import NavigationButtons from '@/components/NavigationButtons/NavigationButtons';
import SwipeableViews from 'react-swipeable-views';
import Banner from '@/components/Banner/Banner';
import banner from '@/assets/images/banner.jpg';


const Menu = () => {

    const {
        establishmentCustom,
        tabIndex,
        menuTypes,
        data,
        setTabIndex,
    } = MenuController();

    return (
        <div className={styles.menuContent}>
            {establishmentCustom &&
                <Banner
                    bannerImage={establishmentCustom.menu_image ?? banner}
                    bannerType={establishmentCustom.menu_background_type ?? '2'}
                    bannerTextColor={establishmentCustom.menu_text_color ?? ''}
                />
            }

            <div className={styles.filterContainer}>
                {/* <MenuFilter
                    setSearchTerm={setSearchTerm}
                    setAllergens={setAllergens}
                    setPhilosophies={setPhilosophies}
                /> */}
            </div>

            <div className={establishmentCustom?.menu_background_type === '1' ? styles.customTabsContainer : styles.tabsContainer}>
                <TabsMenu index={tabIndex} setIndex={setTabIndex} menuTypes={menuTypes} />
            </div>

            <div className={styles.optionsListContainer}>
                <SwipeableViews
                    index={tabIndex}
                    onChangeIndex={(index: number) => setTabIndex(index)}
                    animateHeight={true}
                >
                    {Object.keys(data).map((item, index) => (
                        <OptionsList key={index} optionsList={data[item].products} />
                    ))}
                </SwipeableViews>
            </div>

            <div className={styles.navigationButtonsContainer}>
                {establishmentCustom &&
                    <NavigationButtons
                        backgroundColor={establishmentCustom?.menu_home_button_color ?? ''}
                        textColor={establishmentCustom?.menu_home_button_text_color ?? ''}
                    />
                }
            </div>
        </div>
    );
};

export default Menu;
