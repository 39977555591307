import styles from './Banner.module.scss';

interface BannerProps {
    establishmentName?: string;
    bannerImage: string;
    bannerType: string | null;
    bannerTextColor: string;
}

const Banner = ({ establishmentName, bannerImage, bannerType, bannerTextColor }: BannerProps) => {

    const getBannerStyle = () => {
        if (bannerType === '2') {
            if (!establishmentName) {
                return styles.menuImage;
            }

            return styles.smallImage;
        }

        return styles.image;
    };

    return (
        <div className={styles.container}>
            {bannerType !== '3' && (
                <img src={bannerImage} className={getBannerStyle()} />
            )}
            {bannerType === '2' && <div className={!establishmentName ? styles.menuFade : styles.fade} />}
            <div className={styles.titleContainer}>
                {establishmentName && (
                    <>
                        <p className={styles.welcomeText} style={{ color: bannerTextColor }}>Bienvenid@ a</p>
                        <h1 className={styles.establishmentName} style={{ color: bannerTextColor }}>{establishmentName}</h1>
                    </>
                )}
            </div>
        </div>
    );
};



export default Banner;
